import React from 'react'
const About = () => {
    return (
        <div class='about'>
            <div className='bio'>
                <h3>backstory</h3>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Rhoncus dolor purus non enim praesent elementum. Amet massa vitae tortor condimentum lacinia. Leo vel fringilla est ullamcorper
                    eget nulla. Ipsum dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Sed elementum tempus egestas sed sed.
                    Pretium aenean pharetra magna ac. Eros donec ac odio tempor orci dapibus ultrices in. Proin sagittis nisl rhoncus mattis.
                </p>
            </div>
            <div className='list'>
                <h4>What i know</h4>
                <i className='fab fa-html5'/>
                <i className='fab fa-css3'/>
                <i className='fab fa-js-square'/>
                <i className='fab fa-react'/>
                <i className='fab fa-node-js'/>
            </div>
            <div className='list'>
                <h4>Where ive learned</h4>
                <p>Lambda</p>
                <p>Css Tricks</p>
                <p>W3 Schools</p>
                <p>React Docs</p>
                <p>Redux Docs</p>
                <p>Youtube</p>
            </div>

        </div>
    )
}

export default About