import React from 'react'
import p_img from '../img/profile.jpg'
import {Link}from 'react-router-dom'
const Home = () => {
    return (
        <div className='home'>
            <div className='image'>
                <img src={p_img} alt='austin lynes' />
            </div>
            <div className='text'>
                <h1>Austin Lynes</h1>
                <h3>Full Stack Web Developer</h3>
                <Link to='/about-me' className='link3'>
                    <p>About Me</p>
                </Link>
                <Link to='/repos' className='link3'>
                    <p>repos</p>
                </Link>
                <Link to='/repos' className='link3'>
                    <p>resume</p>
                    {console.log('some name')}
                </Link>
            </div>
        </div>
    )
}

export default Home