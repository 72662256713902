import React from 'react'
import ReactContactForm from 'react-mail-form'
const Contact = () => {
    const divider = '--------------  or  --------------'
    return (
        <div className='contact-form'>
            <h3>Questions?</h3>
            <ReactContactForm
                to='austinlynes2012@gmail.com'
                className='contact-me'
                titlePlaceholder='Title'
                contentsPlaceholder='send me a message?'
            />
            <p className='divider'>{divider}</p>
            <a href='https://www.linkedin.com/in/austin-lynes-2b274518a/'>
                <i className='fab fa-linkedin'></i>
            </a>
            <a  href='https://www.facebook.com/austin.lynes.1?ref=bookmarks'>
                <i className='fab fa-facebook'></i>
            </a>
        </div>)
}

export default Contact