import React from 'react'
import './App.scss'
import Navigation from './compoennts/Navigation'
import { Route } from 'react-router-dom'
import Home from './compoennts/Home'
import Contact from './compoennts/Contact'
import About from './compoennts/About'
function App() {
  return (
    <div className="App">
      <Navigation />
      <Route exact path='/' component={Home} />
      <Route exact path='/about-me' component={About} />
      <Route exact path='/contact' component={Contact}/>
    </div>
  );
}

export default App;
