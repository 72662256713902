import React from 'react'
import { NavLink } from 'react-router-dom'

const Navigation = () => {
    return (
        <div className='navigation'>
            <NavLink className='link' to='/'>
                <p>Home</p>
            </NavLink>
            <NavLink className='link' to='/about-me'>
                <p>About me</p>
            </NavLink>
            <NavLink className='link' to='/js-examples'>
                <p>JavaScript Examples</p>
            </NavLink>
            <NavLink className='link' to='/repos'>
                <p>Git-Hub Repos</p></NavLink>
            <NavLink className='link' to='/resume'>
                <p>Resume</p>
            </NavLink>
            <NavLink className='link' to='/contact'>
                <p>Contact Me</p>
            </NavLink>
        </div>
    )
}

export default Navigation